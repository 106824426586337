<template>
  <div>
    <el-timeline>
      <el-timeline-item v-for="log in logs" :key="'log'+log.id" :timestamp="log.time">
        {{ log.content }}
      </el-timeline-item>
    </el-timeline>
  </div>
</template>
<script>
import { getLogList } from '@/api/log'
export default {
  name: 'ProductLog',
  data() {
    return {
      logs: []
    }
  },
  created() {
    this.getLogListApi()
  },
  methods: {
    getLogListApi() {
      getLogList().then(res => {
        this.logs = res.data
      })
    }
  }
}
</script>
